<template>
  <div>
    <h2 class="mb-4">Buffers: Results</h2>

    <p class="mb-2">
      a) Please fill in the table below with the data you collected during the experiment.
    </p>

    <v-simple-table class="mb-6">
      <thead>
        <tr>
          <td style="font-weight: bold">Property</td>
          <td style="text-align: center; font-weight: bold">Buffer A</td>
          <td style="text-align: center; font-weight: bold">Buffer B</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in labResults" :key="result.value">
          <td>
            <stemble-latex :content="result.property" />
          </td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input
              v-model="inputs[result.value1]"
              :placeholder="result.placeholder1"
              :disabled="result.disabledStatus1"
              @input="inputs[result.value1] = $event !== '' ? Number($event) : null"
            />
          </td>
          <td class="centered-input py-2 px-2 mx-0">
            <calculation-input
              v-model="inputs[result.value2]"
              :placeholder="result.placeholder2"
              :disabled="result.disabledStatus2"
              @input="inputs[result.value2] = $event !== '' ? Number($event) : null"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-2">
      b) Use the input field below to upload the titration data/graphs for your two buffer
      solutions.
    </p>

    <file-upload-ui-component
      style="width: 100%"
      label="Click here to select a file"
      :max-number-of-files="4"
      :max-file-size="20"
      accepted-file-extensions=".png, .jpg ,.jpeg, .pdf, .xlsx"
      @upload:attachments="attachments = $event"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default {
  name: 'OleMissBuffersReportSheet',
  components: {
    FileUploadUiComponent,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massAcetate1: null,
        volBuffer1: null,
        concAcOH1: null,
        initPH1: null,
        volNaOHNeeded1: null,
        volHClNeeded1: null,
        Veq1: null,

        massAcetate2: null,
        volBuffer2: null,
        concAcOH2: null,
        initPH2: null,
        volNaOHNeeded2: null,
        volHClNeeded2: null,
        Veq2: null,
      },
      attachments: [],
      labResults: [
        {
          property: 'i) Mass of $\\ce{CH3COONa}$ used to prepare buffer (g)',
          value1: 'massAcetate1',
          placeholder1: null,
          value2: 'massAcetate2',
          placeholder2: null,
          disabledStatus1: false,
          disabledStatus2: false,
        },
        {
          property: 'ii) Volume of buffer (mL)',
          value1: 'volBuffer1',
          placeholder1: '100.0',
          value2: 'volBuffer2',
          placeholder2: '100.0',
          disabledStatus1: true,
          disabledStatus2: true,
        },
        {
          property: 'iii) $\\ce{[CH3COOH]}$ (M) in buffer',
          value1: 'concAcOH1',
          placeholder1: '0.10',
          value2: 'concAcOH2',
          placeholder2: '1.0',
          disabledStatus1: true,
          disabledStatus2: true,
        },
        {
          property: 'iv) Initial pH',
          value1: 'initPH1',
          placeholder1: null,
          value2: 'initPH2',
          placeholder2: null,
          disabledStatus1: false,
          disabledStatus2: false,
        },
        {
          property: 'v) $\\text{V}_\\text{NaOH}$ to raise pH by 2 units (mL)',
          value1: 'volNaOHNeeded1',
          placeholder1: null,
          value2: 'volNaOHNeeded2',
          placeholder2: null,
          disabledStatus1: false,
          disabledStatus2: false,
        },
        {
          property: 'vi) $\\text{V}_\\text{HCl}$ to lower pH by 2 units (mL)',
          value1: 'volHClNeeded1',
          placeholder1: null,
          value2: 'volHClNeeded2',
          placeholder2: null,
          disabledStatus1: false,
          disabledStatus2: false,
        },
        {
          property: 'vii) $\\text{V}_\\text{NaOH}$ to reach equivalence point (mL)',
          value1: 'Veq1',
          placeholder1: null,
          value2: 'Veq2',
          placeholder2: null,
          disabledStatus1: false,
          disabledStatus2: false,
        },
      ],
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
